@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Smiley Sans";
  src: url("./fonts/SmileySans-Oblique.otf.woff2");
}

.btn {
  background: linear-gradient(
    92.23deg,
    #ff56f6 21.43%,
    #b936ee 50.63%,
    #3bace2 100%,
    #406aff 117.04%
  );
  box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
}

.btn:hover {
  background: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #3bace2 50.63%,
    #b936ee 100%,
    #ff56f6 117.04%
  );
}

.gradient,
.active {
  background: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #3bace2 50.63%,
    #b936ee 100%,
    #ff56f6 117.04%
  );
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white overflow-x-hidden;
  }
  html {
    @apply overflow-x-hidden;
  }
  img {
    @apply select-none pointer-events-none;
  }
  .img-selectable {
    @apply select-auto pointer-events-auto cursor-pointer;
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-primary hover:from-[#FF56F6] hover:to-[#42A6E3];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}
